import { ContactSection } from "@/components/pages/Home/Contact";
import { HeroSection } from "@/components/pages/Home/Hero";
import { PricingSection } from "@/components/pages/Home/Pricing";
import { TeamSection } from "@/components/pages/Home/Team";
import { globalAppProfiles, globalAppSettings } from "@/lib/cms-data.generated";

export default function Home() {
  return (
    <div className="flex flex-col ">
      <HeroSection />
      <TeamSection coaches={globalAppProfiles} />
      <PricingSection settings={globalAppSettings.global} />
      <ContactSection settings={globalAppSettings.global} />
    </div>
  );
}
