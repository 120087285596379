import { AppUserProfilePublic } from "@/lib/api/models";
import { useActiveLink } from "@/lib/hooks/useActiveLink";
import { getCurrentLang, t } from "@/lib/translations";
import { Anchor, Divider } from "@mantine/core";
import { clsx } from "clsx";
import { AppLink } from "../../AppLink";
import { CoachAppoinmentButton, CoachLanguages, CoachLinks } from "../../CoachComponents";
import classes from "./Team.module.css";

export function TeamSection({ coaches }: { coaches: AppUserProfilePublic[] }) {
  const { ref } = useActiveLink("/#team");

  return (
    <>
      <section id="team" className="container mx-auto min-h-screen gap-12 index-section-padding" ref={ref}>
        <h1 className="shoju index-section-title text-center text-3xl md:text-5xl tracking-wide">
          {t("HOME.TEAM.TITLE")}
        </h1>

        <div className={clsx(classes.teamGrid)}>
          {coaches.map((c) => {
            return <UserCard user={c} key={c.userId} />;
          })}
        </div>
      </section>
    </>
  );
}

function UserCard({ user }: { user: AppUserProfilePublic }) {
  return (
    <>
      <div
        className={clsx(
          classes.coachCard,
          "  flex rounded-md duration-500 hover:scale-[102%]",
          "app-shadow-primary-team border border-[var(--mantine-color-dark-6)] mx-auto",
        )}
      >
        <div className={clsx(classes.cardGradient, "min-h-[10px] w-[8px] min-w-[4px] rounded-l-md")}></div>

        <div className="p-8 flex flex-col flex-1">
          <div className="flex">
            <img
              width="64px"
              height="64px"
              src={user.avatarUrl}
              alt={user.name}
              className="bg-background coach-image h-[64px] w-[64px] rounded-full"
              loading="lazy"
            />

            <div className="flex flex-1 flex-col items-center justify-center p-1">
              <p className="shoju w-full text-center">{user.name}</p>
              <p className=" mt-1 w-full text-center font-bold italic opacity-60">
                {user.about.about[getCurrentLang()]?.title}
              </p>
            </div>
          </div>

          <div className="mt-6 flex flex-1 flex-col gap-6">
            <p className="tracking-wide opacity-70">{user.about.about[getCurrentLang()]?.shortDescription}</p>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3 justify-end">
              <CoachLanguages user={user} />
            </div>

            <div className="flex items-center justify-end ">
              <Anchor
                component={AppLink}
                href={`/coach/${user.userId}/`}
                onClick={() => {
                  if ("umami" in window) {
                    (window.umami as any)?.track("coach-more-info", { coachId: user.userId });
                  }
                }}
              >
                {t("HOME.TEAM.MORE_ABOUT_ME")}
              </Anchor>
            </div>
          </div>

          <Divider className="mt-6" />

          <div className="flex mt-8 justify-between items-center gap-1">
            <CoachLinks user={user} />
            <CoachAppoinmentButton user={user} />
          </div>
        </div>
      </div>
    </>
  );
}
