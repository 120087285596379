import { SettingsModel } from "@/lib/api/models";
import { useActiveLink } from "@/lib/hooks/useActiveLink";
import { IconCheck } from "@/lib/icons/tabler/IconCheck";
import { IconX } from "@/lib/icons/tabler/IconX";
import { t } from "@/lib/translations";
import { Text } from "@mantine/core";
import clsx from "clsx";
import classes from "./Pricing.module.css";

export function PricingSection({ settings }: { settings: SettingsModel }) {
  const { ref } = useActiveLink("/#pricing");

  return (
    <>
      <section id="pricing" className="index-section-padding" ref={ref}>
        <h1 className="shoju index-section-title text-center text-3xl md:text-5xl tracking-wide">
          {t("HOME.PRICING.TITLE")}
        </h1>
        <div
          className={clsx(
            "container mx-auto flex items-center justify-center gap-8  min-[1080px]:pt-16",
            classes.pricingContainer,
          )}
        >
          <PricingCard cardSettings={settings.leftPricingCard} />

          <PricingCard cardSettings={settings.middlePricingCard} recommended />

          <PricingCard cardSettings={settings.rightPricingCard} />
        </div>
        <div className="flex flex-col gap-2 mt-24">
          <Text className="text-center italic mb-4" c="dimmed">
            {t("HOME.PRICING.PURCHASE_OPTIONS")}
          </Text>
          <Text className="text-center italic" c="dimmed">
            {t("HOME.PRICING.LOCK_FEE")}
          </Text>
          <Text className="text-center italic" c="dimmed">
            {t("HOME.PRICING.PREMIUM_NOTICE")}
          </Text>
        </div>
      </section>
    </>
  );
}

function PricingCard({
  cardSettings,
  recommended,
}: {
  cardSettings: SettingsModel["leftPricingCard"];
  recommended?: boolean;
}) {
  return (
    <>
      <div
        className={clsx(
          "bg-[var(--mantine-color-dark-6)] group relative flex flex-col rounded-md p-8 shadow transition-all duration-500 max-[1080px]:hover:scale-105 border border-slate-800",
          recommended && " shadow-appbrand min-[1080px]:-translate-y-16",
          // !recommended && "hover:shadow-appbrand min-[1080px]:hover:-translate-y-6",
          !recommended && "min-[1080px]:hover:-translate-y-6",
          "hover:shadow-appbrand min-[1080px]:hover:scale-[102%]",
        )}
        style={{
          minHeight: "25rem",
          maxWidth: "20rem",
          width: "100%",
        }}
      >
        <div className="mb-6 flex flex-col items-center text-center gap-2">
          <h1 className={clsx("shoju shadow-primary carnet-title text-4xl", classes.carnetTitle)}>
            {t(cardSettings.titleRow1)}
          </h1>
          <h2 className={clsx(classes.carnetTitle, "shoju text-3xl")}>{t(cardSettings.titleRow2)}</h2>
        </div>
        <div className="ml-6 mt-2 flex flex-1 flex-col gap-3">
          {cardSettings.properties.map((p) => {
            return (
              <div className="flex gap-4" key={p}>
                {p.includes("POSITIVE") ? <IconCheck className="text-green-800" /> : <IconX className="text-red-800" />}

                <p className="opacity-80">{t(p)}</p>
              </div>
            );
          })}
        </div>
        <div className="flex flex-col items-center">
          <p className="carnet-title text-5xl font-bold">{t(cardSettings.priceRow)}</p>
          <p className="text-2xl opacity-70">{t(cardSettings.PeriodRow)}</p>
        </div>
        {recommended ? (
          <div className="absolute left-0 bottom-0 -translate-y-1/2 p-6 text-sm shoju -rotate-45 text-orange-600">
            HOT
          </div>
        ) : null}
      </div>
    </>
  );
}
