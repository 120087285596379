import { AppLink } from "@/components/AppLink";
import { SettingsModel } from "@/lib/api/models";
import { useActiveLink } from "@/lib/hooks/useActiveLink";
import { IconBrandInstagram } from "@/lib/icons/tabler/IconBrandInstagram";
import { IconMail } from "@/lib/icons/tabler/IconMail";
import { IconMap } from "@/lib/icons/tabler/IconMap";
import { IconPhoneCall } from "@/lib/icons/tabler/IconPhoneCall";
import { IconSun } from "@/lib/icons/tabler/IconSun";
import { t } from "@/lib/translations";
import { Button, SimpleGrid, Text, Tooltip } from "@mantine/core";
import clsx from "clsx";

export function ContactSection({ settings }: { settings: SettingsModel }) {
  const { ref } = useActiveLink("/#contact");

  return (
    <>
      <section id="contact" className=" w-full mx-auto p-4 index-section-padding" ref={ref}>
        <h1 className="shoju index-section-title text-center md:text-5xl text-3xl tracking-wide">
          {t("HOME.CONTACT.TITLE")}
        </h1>

        <div
          className="
          flex flex-col items-center justify-center px-4 w-full max-w-[800px] mx-auto
          rounded-md
          app-shadow-primary
          border border-[var(--mantine-color-dark-6)]
          transition-all duration-500 hover:scale-[101%]"
        >
          <div
            className={clsx(
              "relative flex flex-col rounded-md items-center shadow transition-all duration-500 p-4",
              "hover:shadow-md hover:brightness-105 hover:shadow-primary",
            )}
          >
            <h1 className="shoju mt-12 mb-16  text-center text-2xl opacity-70">Determinacja</h1>

            <SimpleGrid cols={{ base: 1, sm: 2 }} className="w-full place-content-between" spacing={48}>
              <ContactInfo
                title={t("HOME.CONTACT.EMAIL_LABEL")}
                value={settings.email}
                href={"mailto:" + settings.email}
                Icon={<IconMail className="opacity-70" />}
              ></ContactInfo>
              <ContactInfo
                title={t("HOME.CONTACT.ADDRESS_LABEL")}
                value={settings.address}
                href={settings.addressLink}
                Icon={<IconMap className="opacity-70" />}
              ></ContactInfo>

              <div className={clsx("flex gap-4 ")}>
                <IconPhoneCall className="opacity-70" />

                <div className="flex flex-col gap-1">
                  <p className="opacity-70">{t("HOME.CONTACT.CONTACT_PHONE_NUMBERS_LABEL")}</p>
                  <div className="flex flex-col gap-2">
                    {settings.phoneNumbers.map((pNumber) => {
                      return (
                        <div className="flex items-center gap-4" key={pNumber?.phoneNumber?.toString()}>
                          <a
                            href={"tel:" + pNumber.phoneNumber}
                            key={pNumber.phoneNumber}
                            target="_blank"
                            rel="noreferrer"
                            aria-label={t("HOME.CONTACT.PHONE_NUMBER_PLACEHOLDER")}
                            className="flex items-center gap-3"
                          >
                            <p>{pNumber.phoneNumberDisplayName}</p>
                          </a>
                          <div className="min-h-[1rem] w-[1px] bg-slate-800"></div>

                          {pNumber.languages?.map((l) => {
                            return (
                              <Tooltip label={t(l.tooltipTranslationCode)} key={l.labelTranslationCode}>
                                <div className="rounded-md opacity-70">{t(l.labelTranslationCode)}</div>
                              </Tooltip>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <ContactInfo
                title={t("HOME.CONTACT.OPENING_HOURS_LABEL") + " *"}
                value={t(settings.workingHours)}
                Icon={<IconSun className="opacity-70" />}
              ></ContactInfo>
            </SimpleGrid>

            <div className="w-full h-[1px] mt-8"></div>

            <div className="mt-6 flex justify-between w-full items-center">
              <Text c="dimmed">* {t("HOME.CONTACT.OPENING_HOURS_STAR")}</Text>
              <Tooltip label="Instagram">
                <Button
                  component={AppLink}
                  to={settings.instagramLink}
                  target="_blank"
                  variant="transparent"
                  aria-label="Instagram"
                >
                  <IconBrandInstagram />
                </Button>
              </Tooltip>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

function ContactInfo({
  className,
  href,
  Icon,
  title,
  value,
}: {
  title: string;
  href?: string;
  value: string;
  Icon: React.ReactNode;
  className?: string;
}) {
  return (
    <>
      <div className={clsx("flex gap-4 ", className)}>
        {Icon}
        {href ? (
          <a href={href} target="_blank" rel="noreferrer noopener" aria-label={title} className="flex flex-col gap-1">
            <p className="opacity-70">{title}</p>
            <p>{value}</p>
          </a>
        ) : (
          <div className="flex flex-col gap-1">
            <p className="opacity-70">{title}</p>
            <p>{value}</p>
          </div>
        )}
      </div>
    </>
  );
}
