import { AppLink } from "@/components/AppLink";
import { useActiveLink } from "@/lib/hooks/useActiveLink";
import { IconBarbell } from "@/lib/icons/tabler/IconBarbell";
import { IconBolt } from "@/lib/icons/tabler/IconBolt";
import { IconHeartHandshake } from "@/lib/icons/tabler/IconHeartHandshake";
import { IconUsersGroup } from "@/lib/icons/tabler/IconUsersGroup";
import { t } from "@/lib/translations";
import { Button } from "@mantine/core";
import clsx from "clsx";
import classes from "./Hero.module.css";

export function HeroSection() {
  const { ref } = useActiveLink("/");

  return (
    <>
      <section id="#" className="-mt-header" ref={ref}>
        <div
          className={
            classes.bgImage +
            `
          flex px-16 pt-14
          items-center justify-center gap-12
        `
          }
        >
          <HeroLeftImage />

          <div className={"flex flex-1 flex-col !opacity-100 z-20 items-center justify-center gap-14"}>
            <HeroTitle />

            <HeroPills />

            <HeroDescriptionGroup />

            <HeroActionButton />
          </div>
        </div>
      </section>
    </>
  );
}

function HeroLeftImage() {
  return (
    <figure className={"flex flex-1 flex-col gap-4 relative " + classes.heroImageWrapper + " " + classes.heroImageHide}>
      <img
        fetchPriority="high"
        loading="eager"
        width="768"
        height="576"
        src="https://cdn.determinacjagym.pl/hero-image-768-brighter.webp"
        className="rounded-lg"
        // alt="A fit man"
        alt={t("HOME.HERO.LEFT_IMAGE_ALT")}
      />
      <figcaption className={"text-center text-xl opacity-60 z-20 " + classes.heroImageHide}>
        {t("HOME.HERO.CATCH_PHRASE")}
      </figcaption>
    </figure>
  );
}

function HeroTitle() {
  return (
    <h1 className={clsx("shoju text-center tracking-widest text-4xl min-[800px]:text-5xl leading-snug")}>
      {t("HOME.HERO.TITLE")}
    </h1>
  );
}

function HeroPills() {
  return (
    <div
      className="
  flex flex-col gap-6 md:gap-14
  min-[800px]:flex-row
  tracking-wider
"
    >
      <div className="flex items-center w-52 gap-4 text-nowrap  lg:justify-center">
        <div className="">
          <IconBarbell />
        </div>
        <p className="prop">{t("HOME.HERO.PROFFESIONAL_GEAR")}</p>
      </div>
      <div className="flex items-center w-52 gap-4 text-nowrap lg:justify-center">
        <div className="">
          <IconUsersGroup />
        </div>
        <p className="prop">{t("HOME.HERO.ACTIVE_COMMUNITY")}</p>
      </div>
      <div className="flex items-center w-52 gap-4 text-nowrap lg:justify-center">
        <div className="">
          <IconHeartHandshake />
        </div>
        <p className="prop">{t("HOME.HERO.COACHES_WITH_PASSION")}</p>
      </div>
    </div>
  );
}

function HeroDescriptionGroup() {
  return (
    <>
      <div
        className="
          hidden
          min-[800px]:flex min-[800px]:flex-col
          items-center justify-center gap-4
          max-w-[51.25rem]
          tracking-wide
       "
      >
        <p className={"tracking-wide opacity-60"}>{t("HOME.HERO.DESCRIPTION_1")}</p>
        <p className={clsx("mt-3 tracking-wide opacity-60")}>{t("HOME.HERO.DESCRIPTION_2")}</p>
      </div>
    </>
  );
}

function HeroActionButton() {
  return (
    <div className="button-wrapper flex w-full items-end justify-center mt-2">
      <Button
        leftSection={<IconBolt />}
        rightSection={<IconBolt />}
        fullWidth
        maw={300}
        variant="filled"
        component={AppLink}
        color="#8d5600"
        to="/#team"
        size="md"
      >
        {t("HOME.HERO.ACTION_BUTTON")}
      </Button>
    </div>
  );
}
